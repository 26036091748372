import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCard, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location, pageContext }) => {
    const posts = data.allGhostPost.edges

    return (
        <>
            <MetaData location={location} />
            <Layout isHome={true}>
                <section className="py-12 px-4">
                    <div className="flex flex-wrap items-center text-center lg:text-left -mx-2">
                        <div className="lg:w-1/2 px-2 lg:pr-10 mt-10 lg:mt-0 order-1 lg:order-none">
                            <h2 className="text-5xl mb-6 leading-tight font-heading">Get the most out of January sales</h2>
                            <p className="mb-8 text-gray-500 leading-relaxed">Plan ahead to get the most out of your New Year and January sales.
                            Schedule flash sales, storewide sales and daily deals ahead of time.</p>
                            <div><a
                                className="inline-block py-4 px-8 mr-6 leading-none text-white bg-indigo-500 hover:bg-indigo-600 rounded shadow"
                                href="https://apps.shopify.com/discount-app?referrer=pe">Try Disco</a><a className="text-blue-700 hover:underline" href="https://apps.shopify.com/discount-app?referrer=pe">Learn more</a></div>
                        </div>
                        <div className="lg:w-1/2 px-2"> <img src="images/Key_Benefit_Images_1.png" alt="" className="rounded-tr-full" />
                        </div>
                    </div>
                </section>

                <section className="py-4 px-4">
                    <div className="text-center"><span className="text-indigo-600">Premium Shopify discount app</span>
                        <h2 className="text-3xl text-center mb-12 font-heading">Features</h2>
                    </div>
                    <div className="flex flex-wrap max-w-5xl -mx-4 md:mx-auto -mb-8">
                        <div className="w-full md:w-1/2 px-4 mb-8">
                            <div className="h-full p-6 bg-white rounded border-t-4 border-indigo-500 shadow-md">
                                <h3 className="text-2xl mb-3">Storewide sale, little effort</h3>
                                <p>Easily create a sale in your store with a single click. Select products by product type, vendor, tag or
                collection and set a discount.</p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-4 mb-8">
                            <div className="h-full p-6 bg-white rounded border-t-4 border-indigo-500 shadow-md">
                                <h3 className="text-2xl mb-3">Add urgency to sale</h3>
                                <p>Add a live countdown timer to create urgency. Shows time left of sale on each discounted product. Great
                for flash-sales and daily deals.</p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-4 mb-8">
                            <div className="h-full p-6 bg-white rounded border-t-4 border-indigo-500 shadow-md">
                                <h3 className="text-2xl mb-3">Black Friday + Cyber Monday</h3>
                                <p>The easiest way to run your a BFCM sale. Automatically create a collection to display in store or share
                with newsletter.</p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-4 mb-8">
                            <div className="h-full p-6 bg-white rounded border-t-4 border-indigo-500 shadow-md">
                                <h3 className="text-2xl mb-3">Schedule sales</h3>
                                <p>Save stress and time by using Disco for planning your Black Friday (and) Cyber Monday Sale, Flash Sales
                or Daily Deals ahead of time.</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-12"><a
                        className="inline-block py-4 px-8 leading-none text-white bg-indigo-500 hover:bg-indigo-600 rounded"
                        href="https://apps.shopify.com/discount-app?referrer=pe">Try Disco for free</a></div>
                </section>

                {/*
                <div className="container">
                    <section className="post-feed">
                        {posts.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCard key={node.id} post={node} />
                        ))}
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
                        */}
            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`
